/*
 * @Author: 赵亚鑫Deep Lane
 * @Date: 2021-08-04 13:56:11
 * @LastEditors: 赵亚鑫Deep Lane
 * @LastEditTime: 2022-05-06 18:53:48
 * @Description: 
 */
import style from './style.module.css'
interface Props {
  value: boolean | undefined
  className?: string
  onChange: (value: boolean) => void
}
export default function Checkbox(props: Props) {
  function className() {
    let name = 'iconfont ' + style.checkbox
    if (props.value === true) name += ' icon-roundcheckfill ' + style.checked
    else name += ' icon-round'
    if (props.className) name += ' ' + props.className
    return name
  }
  return (
    <i className={className()} onClick={() => props.onChange(!props.value)} />
  )
}
